import { Interpreter } from 'eval5'
var esprima = require('esprima')
const crfRuleUtils = {
	getVariables(rule,items){
		var tokens = esprima.tokenize(rule)
		// console.log(tokens)
		var vars = []
		tokens.forEach(function(token){
			if(token.type == 'Identifier' && items.some(item => {if(item.name == token.value){return true}})){
				let item = items.find(item => {if(item.name == token.value){return item}})
				if(item != undefined&&vars.indexOf(item)==-1){
					vars.push(item)
				}
			}
		})
		return vars
	},
	exec(data,rule,vars){
		// console.log("计算exec")
		// console.log("data",data)
		// console.log("rule",rule)
		var rootContext = {}
		vars.forEach(function(v){
			if(data['__'+v.name] != undefined && data['__'+v.name]['value'] != undefined){
				if(v.datatype == 'INT'){
					try{
						rootContext[v.name] = parseInt( data['__'+v.name]['value'] )
					}catch(e){
						e.parse()
					}
				}else if(v.datatype ==  'REAL'){
					try{
					rootContext[v.name] = parseFloat( data['__'+v.name]['value'] )
					}catch(e){e.parse()}
				}else{
					rootContext[v.name] =  data['__'+v.name]['value']
				}
			}else{
				if(v.name.indexOf("jbzcbg")>-1){
					// console.log("疾病自测报告未填item")
					rootContext[v.name] =  0
				}
			}
		})
		var interpreter = new Interpreter(window, {
			rootContext: rootContext,
		})
		try{
			let re = interpreter.evaluate(rule)
			return re
		}catch(e){
			// console.log(e)
			return ''
		}
	}
}

export default crfRuleUtils