<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="问卷"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <vfrom @submit="onSubmit">
            <tabs v-model="active" scrollspy sticky ellipsis   color="black" background="#e0e0e0" >
                <tab :title="crf_title">
                    <Divider :style="{ color: '#e0e0e0', borderColor: '#e0e0e0', padding: '0 16px' }" ><h3  class="stitle">{{crf_title}}</h3></Divider>
                    <div v-for="(item,index) in crf"  :key="index"  >
                        <!--                        item-->
                        <div  v-if="item.tagType!='header'&&bwimg!=null&&bwimg==index">
                            <div  v-for="(idx,index) in bwInfo.imgs" :key="index">
<!--                                <img fit="cover" width="100%" :src="require('../../img/bwimg/'+idx)" :id="'bw_'+idx" :imageonload="loadFinish('can',idx)" style="display: none"/>-->
                                <img fit="cover" width="100%" :src="picUrl+idx" :id="'bw_'+idx" :imageonload="loadFinish('can',idx)" style="display: none"/>
                                <!--                                <canvas :id="'can_'+idx"></canvas>-->
                            </div>
                        </div>
                        <div v-if="item.name=='sqpgwj0025' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjzhzm.jpg'"/>
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjzhhm.jpg'"/>
                        </div>
                        <div v-if="item.name=='sqpgwj0159' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjzhwd.png'"/>
                        </div>
                        <div v-if="item.name=='shpgwj0003' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m1.png'"/>
                        </div>
                        <div v-if="(item.name=='shpgwj0008' || item.name=='shpgwj0009') && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m2.png'"/>
                        </div>
                        <div v-if="item.name=='shpgwj0010' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m3.png'"/>
                        </div>
                        <div v-if="item.name=='shpgwj0011' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m4.png'"/>
                        </div>
                        <div v-if="item.name=='shpgwj0012' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m5.png'"/>
                        </div>
                        <div v-if="item.name=='shpgwj0103' && currentitem==index">
                          <img fit="cover" width="100%" :src="gjjzhPicUrl+'gjjshzh1-3m6.png'"/>
                        </div>
                        <div v-if="item.tagType!='header'&&(currentitem==index||showvas.indexOf(index)>-1)"  >
                            <RilinItem :item="item" :name="'__'+item.name" mtype="item"  :mdata="item" :gord="0" :data="crfdatas"  :rules="crfRules" :ruleitems="ruleItems"></RilinItem>
                        </div>
                        <!--                        header-->
                        <div v-if="item.tagType=='header'&&showHeaders.indexOf(item.no)!=-1&&!item.phidden" >
                            <label style="white-space: pre-wrap">{{item.header}}</label>
                        </div>
                    </div>
                </tab>
            </tabs>
            <div style="margin-left: 20px;margin-top: 10px;font-size:14px">
                <vcheckbox v-model="isagree" v-if="isFinish == 1" icon-size="12" shape="square" @change="changeAgree">
                    我已阅读并同意
                    <a @click="showYSZC" style="color: #007aff">《关节通隐私政策及协议》</a>
                </vcheckbox>

            </div>
            <div style="margin: 16px;">
                <Button round block type="info" v-if="isFinish == 1" :disabled="agreeStatus||sub" native-type="submit" >提交</Button>
            </div>
        </vfrom>
        <div style="margin: 16px;">
            <Button round block type="info" v-if="isStart == 0" @click="preItem()" >上一题</Button>
        </div>
        <div style="margin: 16px;">
            <Button round block type="info" v-if="isFinish == 0" @click="nextItem" >下一题</Button>
        </div>

        <!--        <empty v-if="nodata" description="查无数据" />-->
        <ActionSheet v-model="showYszc" title="关节通隐私政策及协议">
            <div class="content" style="margin-left: 2em;margin-right: 2em;text-indent:2em;font-size:13px">
                <p></p>
                <p>1.适用范围</p>
                <p>1.1本政策仅适用于关节通微信公众号服务（以下简称“关节通”）的运营主体及客户端服务可能存在的运营关联单位，具体指深圳市瑞林泰克科技有限公司（以下简称“瑞林泰克”）。使用前述关节通服务的使用人在本政策中称为“用户”，或称为“您”。 </p>
                <p>1.2请您在使用微信公众号服务前，仔细阅读并充分理解本隐私保护政策注册协议。您在点击“确认”按钮后，本政策即构成对双方有约束力的法律文件，即表示您同意瑞林泰克按照本政策收集、使用、处理和存储您的相关信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第5条提供的联系方式与我们联系。 </p>
                <p>1.3瑞林泰克深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，瑞林泰克承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
                <p></p>
                <p>2.隐私保护政策具体内容</p>
                <p>2.1 我们如何收集和使用信息 </p>
                <p>我们会按照如下方式收集您在使用服务时主动提供的、以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息： </p>
                <p>2.1.1注册问卷： </p>
                <p>当您关注公众号提交注册问卷时，注册问卷中个人信息和其他有无关节不适信息。 </p>
                <p>2.1.2沟通功能 </p>
                <p>医患沟通中咨询的运动训练及康复情况等交流信息。 </p>
                <p>2.1.3定向推送 </p>
                <p>我们会基于收集的信息，推送相关的科普图文、运动康复训练视频、量表。为此，我们需要收集的信息包括您的量表内容。 </p>
                <p>2.1.4安全运行 </p>
                <p>安全保障功能我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。读、写入外部存储，唯一标识符为实现安全运行所收集的必要权限和信息。 </p>
                <p></p>
                <p>2.2.我们如何共享、转让、公开披露您的个人信息 </p>
                <p>2.2.1共享 </p>
                <p>我们不会向瑞林泰克以外的任何公司、组织和个人分享您的个人信息，但以下情况除外： </p>
                <p>2.2.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。 </p>
                <p>2.2.1.2我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。  </p>
                <p>2.2.1.3与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息及问卷信息，以提供更好的客户服务和用户体验。例如，根据个人信息和问卷信息情况医师更有针对性咨询和推送相关视频及问卷。 </p>
                <p>目前，我们的授权合作伙伴包括各地区医疗服务类的授权合作伙伴。  </p>
                <p>2.2.2转让 </p>
                <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： </p>
                <p>2.2.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； </p>
                <p>2.2.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。 </p>
                <p>2.2.3公开披露 </p>
                <p>我们仅会在以下情况下，公开披露您的个人信息： </p>
                <p>2.2.3.1获得您明确同意后； </p>
                <p>2.2.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景： </p>
                <p>a.与国家安全、国防安全直接相关的； </p>
                <p>b.与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>c.与犯罪侦查、起诉、审判和判决执行等直接相关的； </p>
                <p>d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>e.所收集的您的个人信息是您自行向社会公众公开的； </p>
                <p>f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； </p>
                <p>g.根据您的要求签订或履行合同所必需的； </p>
                <p>h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障； </p>
                <p>i.为合法的新闻报道所必需的； </p>
                <p>j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； </p>
                <p>k.法律法规规定的其他情形。 </p>
                <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
                <p></p>
                <p>2.3.我们如何存储和保护信息 </p>
                <p>2.3.1 存储地点 </p>
                <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将遵循相关国家规定或者征求您的同意。 </p>
                <p>2.3.2存储期限 </p>
                <p>我们仅在为提供关节通及服务之目的所必须的期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。 </p>
                <p>2.3.3 技术措施与数据保护措施 </p>
                <p>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 </p>
                <p>2.3.4 安全事件处理 </p>
                <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                <p></p>
                <p>3.您的权利 </p>
                <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利： </p>
                <p>3.1访问您的个人信息 </p>
                <p>如果您希望访问或编辑 您的账户中个人账户及注册问卷信息，您可以通过访问个人中心—>我的信息进行查看。   </p>
                <p>3.2访问您的健康记录 </p>
                <p>如果您希望访问或编辑 您的每日健康记录，您可以通过访问个人中心—>健康记录进行查看。</p>
                <p>3.3访问您的收到的手术康复视频 </p>
                <p>如果您希望访问收到的手术康复视频，您可以通过访问个人中心—>康复方案进行查看。 </p>
                <p>3.4访问您的收到的非手术运动视频 </p>
                <p>如果您希望访问您的收到的非手术运动视频，您可以通过访问个人中心—>非术后视频。 </p>
                <p>3.5约束信息系统自动决策 </p>
                <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释式。 </p>
                <p>3.6响应您的上述请求 </p>
                <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。 </p>
                <p>在以下情形中，我们将无法响应您的请求： </p>
                <p>a、与个人信息控制者履行法律法规规定的义务相关的； </p>
                <p>b、与国家安全、国防安全直接相关的； </p>
                <p>c、与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>d、与犯罪侦查、起诉、审判和执行判决等直接相关的； </p>
                <p>e、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的； </p>
                <p>f、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>g、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的； </p>
                <p>h、涉及商业秘密的。</p>
                <p></p>
                <p>4.本政策的适用及更新 </p>
                <p>我们可能会不时对《关节通隐私政策及协议》进行修订，当《关节通隐私政策及协议》发生重大变更修订时，我们会在版本更新后以推送通知或弹窗等形式向您展示变更后的内容。请您注意，只有在您确认修订后的《关节通隐私政策》后，我们才会按照修订后的《关节通隐私政策及协议》收集、使用、处理和存储您的个人信息；您可以选择不同意修订后的《关节通隐私政策及协议》，但可能导致您无法使用关节通的部分服务及功能。 </p>
                <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。 </p>
                <p>本政策所指的重大变更包括但不限于： </p>
                <p>a、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等； </p>
                <p>b、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； </p>
                <p>c、个人信息共享、转让或公开披露的主要对象发生变化； </p>
                <p>d、您参与个人信息处理方面的权利及其行使方式发生重大变化； </p>
                <p>e、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； </p>
                <p>f、个人信息安全影响评估报告表明存在高风险时。 </p>
                <p></p>
                <p>5.如何联系我们 </p>
                <p>我们已经设立了个人信息保护负责人员，如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系： </p>
                <p>电话：4000-365-880 </p>
                <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。 </p>
                <br>
                <br>
            </div>
        </ActionSheet>
        <br>
        <br>
        <RLFooter/>
    </div>
</template>

<script>

    import { Tab, Tabs ,Form as vfrom,Button,ActionSheet,Checkbox as vcheckbox} from 'vant';
    // import RilinGroup from '@/components/RilinGroup';
    import RilinItem from '@/components/RilinItem';
    import crfRuleUtils from '@/utils/crfRuleUtils.js';
    // import {getCrf} from '@/api/follow';
    import {getFollowCrf,saveFollowCrf,getTjzbItem} from '@/api/follow';
    import {getBwPicInfo} from '@/api/crfs';
    import {getUser} from '@/api/user';
    import {setToken, removeToken, getToken} from '@/utils/auth';

    export default {
        name: 'QueIdx',
        components: {
            Tab, Tabs,RilinItem,vfrom,Button,ActionSheet,vcheckbox
        },
        data(){
            return {
                user:{
                    name:'',
                    idCard:'',
                    telephone:'',
                    gender:'1',
                    birthday:'',
                    address:'',
                    detail_address:'',
                    id:''
                },
                active:0,
                crf:{},
                crf_title:'',
                crfdatas:{},
                currentitem:0,
                total:0,
                isStart:1,
                isFinish:0,
                showFwxy:false,
                showYszc:false,
                isagree:false,
                agreeStatus:true,
                sendid:null,
                itemdataorderid:null,
                crfid:null,
                follow_time:null,
                showvas:[],
                bwimg:null,
                bwInfo:null,
                sizeRa:null,
                syswidth:document.body.clientWidth,
                zoom:null,
                bwAreaColorArr:[],
                // is_diary:0,
                is_type:0,
                picUrl:'https://guanjietong.rilintech.com/bwimg/',
                gjjzhPicUrl:'https://guanjietong.rilintech.com/gjjzhwj/',
                showQsbw:false,
                showHeaders:[],
                tjzbMap:{},
                sub:false,
                subText:"提交",
                crfRules: [],
                watchHandlers: [],
                ruleItems:[],
            };
        },
        provide(){
            return{
                drawPic:this.drawPic
            }
        },
        computed:{

        },
        watch:{
          'crfRules.length': {
            handler(){
            // handler(newVal){
              // console.log("监听规则")
              // console.log(newVal)
              var that = this
              that.$ruleBus.$off('emit')
              that.watchHandlers.forEach(function(unwatch){
                // console.log("监听规则2")

                unwatch.call()
              })
              that.crfRules.forEach(function(rule){
                let vars = crfRuleUtils.getVariables(rule.formula, that.crf)
                // that.ruleItems = vars
                // console.log("监听规则3")

                // that.ruleItems = Object.assign(that.ruleItems, vars);
                that.ruleItems.push.call(that.ruleItems, ...vars);

                // console.log(that.ruleItems)
                // that.$ruleBus.$on(rule.itemname,function(fromItem){
                that.$ruleBus.$on(rule.itemname,function(){
                //     console.log("rule事件：" + rule.itemname + ",来自：" + fromItem)
                  //   if(rule.itemname=="eq5d00280"){
                  //     console.log("eq5d00280")
                  //     console.log(that.crfdatas)
                  //   }
                  // that.data['$patient'] = that.patient
                  // that.data['$order'] = that.order
                  let val = crfRuleUtils.exec(that.crfdatas, rule.formula , that.ruleItems)
                  that.setData('','', rule.itemname, {text:'',value:val})
                })

                that.ruleItems.forEach(function(v){
                  // console.log("v_foreach")
                  that.watchHandlers.push(that.$watch('data.' + v.name+'_0',() => {
                  // that.watchHandlers.push(that.$watch('data.' + v.name+'_0',(newVal, oldVal) => {
                    // console.log(newVal, oldVal)
                    // console.log(that.watchHandlers)

                    that.$ruleBus.$emit(rule.itemname, v.name)
                  }))
                })
              })
            },
            deep:true
          }
        },
        methods:{
            initData(){
                console.info(this.$store.state.user);
                console.info(this.$route.params.crfid);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;
                let crfname=query.crfname;
                this.sendid=query.sendid;
                this.itemdataorderid=query.itemdataorderid;
                this.follow_time=query.follow_time;
                // this.is_diary=query.is_diary;
                this.is_type=query.is_type;
                //注册问卷crfid
                // let register_crf_id = "0b6ed707cd8c4e38ad96b8a9177abb97"
                // let register_crf_name = "crf-zsyy-0001"
                getFollowCrf({patientid:this.user.id,crfname:crfname,itemdataorderid:this.itemdataorderid}).then((response) => {
                    console.log("问卷内容")
                    console.log(response)
                    // console.log(response.data.formmetadatas.length)

                    if(response.code==200 ){
                        this.crfid = response.data.crfid
                        this.crf=response.data.data;
                        this.total = this.crf.length;
                        this.crf_title = response.data.name
                        this.crfdatas = response.data.datas
                        this.crfRules = response.data.rule

                        if(this.crf[0].no.indexOf('vas_00020')> -1){
                            this.bwInfo = getBwPicInfo(this.crf[0].no.split('vas')[0]+'vas')
                            console.log(this.bwInfo)
                            if(response.data.itemdataorderid != null){
                                this.loadColor()
                            }
                        }
                      for(let i=0;i<this.crf.length;i++){
                        if(this.crf[i].tagType == "header"){
                          this.showHeaders.push(this.crf[i].no)
                          // this.currentitem ++;
                          if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasstart')>-1){
                            console.log(1)
                            for(let i=this.currentitem;i<this.total;i++){
                              if(this.crf[i].name.indexOf('vasend')>-1){
                                break
                              }
                              this.currentitem = this.currentitem+1
                            }
                          }
                          this.currentitem = this.currentitem+1

                          if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasstart')>-1){
                            this.bwimg = this.currentitem
                            for(let i=0;i<this.total;i++){
                              if(i == this.currentitem){
                                this.showvas.push(this.currentitem)
                                if(this.crf[i].name.indexOf('vasend')>-1){
                                  break
                                }
                                this.currentitem ++;
                              }
                            }
                          }else{
                            this.bwimg = null
                            this.showvas=[]
                          }
                        }else{
                          break
                        }
                      }
                      let checkHidden = this.currentitem
                      for(let i=this.currentitem;i<this.total-1;i++){
                        if(this.crf[i+1].phidden==1){
                          checkHidden ++;
                        }else{
                          break
                        }
                      }
                      if(checkHidden>=this.total-1){
                        this.isStart =0
                        this.isFinish =1
                        // console.log("当前是第"+(this.currentitem+1)+"题")
                      }
                        // console.log(this.bwAreaColorArr)

                    }

                    if(this.is_type == "2"||this.is_type == 2){
                      console.log(this.user.id)
                      getTjzbItem({patientid:this.user.id}).then((response) => {
                        console.log(response)
                        if(response.code == 200){
                          let new_crf = []
                          this.tjzbMap = response.data
                          for(let i=0;i<this.crf.length;i++){
                            for(let key in this.tjzbMap){
                              let isShow = this.tjzbMap[key]
                              if(this.crf[i].name == key && isShow == true){
                                new_crf.push(this.crf[i])
                                break
                              }
                            }

                          }
                          this.crf = new_crf
                          this.total = this.crf.length;
                          console.log("newCrf")
                          console.log(this.crf)
                          console.log(this.total)
                        }

                      })
                    }
                });

            },
            loadColor(){
                this.crf.forEach((data)=>{
                    // console.log(index)
                    // console.log(data)

                    if(data.tagType == 'slider'){
                        if(data.name.indexOf("vas") >-1){
                            // console.log("初始化数据")
                            if("__"+data.name in this.crfdatas){
                                data.selectedValue = this.crfdatas["__"+data.name].value;
                                data.selectedText = this.crfdatas["__"+data.name].text;
                            }else{
                                data.selectedValue = -1;
                                data.selectedText = -1;
                            }
                            // data.selectedValue = this.crfdatas["__"+data.name].value;
                            // data.selectedText = this.crfdatas["__"+data.name].text;
                            // console.log(data.selectedText)

                            // console.log(data.selectedText)
                            if(data.selectedText!= null ){
                                // $scope.initDraw = 1;

                                let indexTemp = ~~data.name.substr(0,data.name.length-1).replace("00","__").split("__")[1]
                                // $scope.bwAreaArr[indexTemp-2] = data.selectedText
                                if(data.selectedText ==0){
                                    // this.bwAreaColorArr[indexTemp-2] = "#fcfcfc"
                                    this.bwAreaColorArr[indexTemp-2] = "#fcfcfc"
                                }else if(data.selectedText>=1&&data.selectedText<=3){
                                    this.bwAreaColorArr[indexTemp-2] = "#09cb0e"

                                }else if(data.selectedText>=4&&data.selectedText<=6){
                                    this.bwAreaColorArr[indexTemp-2] = "#f6fc09"

                                }else if(data.selectedText>=7&&data.selectedText<=9){
                                    this.bwAreaColorArr[indexTemp-2] = "#fc9b0f"

                                }else if(data.selectedText==10){
                                    this.bwAreaColorArr[indexTemp-2] = "#a70707"

                                }

                            }

                        }
                    }
                })
                // console.log(this.bwAreaColorArr)
            },
            loadFinish(can,imgName){
                // this.loadColor()
                // console.log(this.bwAreaColorArr)

                // console.log(can,imgName)
                this.initCanvas(can,imgName)

                setTimeout(()=>{
                    let areaArrTemp = this.bwInfo.areas[imgName]
                    // let imgArrTemp = this.bwInfo.imgs
                    for(let j=0;j<areaArrTemp.length;j++){
                        if(areaArrTemp[j]!=undefined&&areaArrTemp[j]!=null){
                            if(areaArrTemp[j].type==0){//圆
                                // console.log("画圆")
                                this.drawPicCircle(can,imgName,areaArrTemp[j],this.bwAreaColorArr[j])
                            }else if(areaArrTemp[j].type==1){//方
                                // console.log("画方")
                                // console.log(this.bwAreaColorArr[j])
                                this.drawPicRect(can,imgName,areaArrTemp[j],this.bwAreaColorArr[j])

                            }
                        }

                    }
                },100)
            },
            initCanvas(can,imgName) {
                let canvas = document.getElementById(can+"_"+imgName)

                if(canvas  == null){
                    canvas =  document.createElement('canvas')
                    canvas.id = can+"_"+imgName

                }
                // 获取对应的CanvasRenderingContext2D对象(画笔)
                let context = canvas.getContext('2d')
                // 创建新的图片对象
                let img = new Image()
                // 指定图片的DataURL(图片的base64编码数据)
                // img.src = require('../../img/bwimg/'+imgName)
                img.src = this.picUrl+imgName
                //画布宽度
                let width = this.syswidth
                this.sizeRa = width/345
                // console.log(this.sizeRa)
                // 监听浏览器加载图片完成，然后进行绘制
                img.onload = () => {
                    //画布大小按照图片的比例生成
                    let height = width / (img.naturalWidth / img.naturalHeight)

                    // 指定canvas画布大小，该大小为最后生成图片的大小
                    canvas.width = width
                    canvas.height = height
                    context.clearRect(0,0,canvas.width,canvas.height)

                    /* drawImage画布绘制的方法。(0,0)表示以Canvas画布左上角为起点， canvas.width, canvas.height 是将图片按给定的像素进行缩小。*/
                    /* 如果不指定缩小的像素，图片将以图片原始大小进行绘制，图片像素如果大于画布将会从左上角开始按画布大小部分绘制图片，最后得到的图片就是张局部图。图片小于画布就会有黑边。*/
                    context.drawImage(img, 0, 0, canvas.width, canvas.height)
                    // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量

                    document.getElementById("bw_"+imgName).after(canvas)
                    // document.getElementById("bw_"+imgName).style.display = 'none'
                }

                // this.canvas[can+"_"+imgName] = document.getElementById(can+"_"+imgName);
                //
                // let img = document.getElementById("bw"+index+"_"+imgName);

                // console.log($scope["width_"+imgName])
                // if(this.widths["width_"+imgName]==undefined){
                //
                //     this.widths["width_"+imgName] = img.width
                //     this.heights["height_"+imgName] = img.height
                //
                //     // console.log($scope.imageName+"的宽高为:")
                //     // console.log($scope["width_"+imgName]+"*"+$scope["height_"+imgName])
                //
                //     this.sizeRa = this.widths["width_"+imgName]/345
                // }
                //
                //
                //
                // canvas.style.width = '100%';
                // canvas.getContext("2d").clearRect(0,0,this.widths["width_"+imgName],this.heights["height_"+imgName])
                //
                // canvas.setAttribute("width",this.widths["width_"+imgName]);
                // canvas.setAttribute("height",this.heights["height_"+imgName]);
                //
                // canvas.getContext("2d").drawImage(img, 0, 0,this.widths["width_"+imgName], this.heights["height_"+imgName]);
                //
                // img.style.display = "none"
                // this.canvas[can+"_"+imgName].style.width = '100%';
                // this.canvas[can+"_"+imgName].getContext("2d").clearRect(0,0,this.widths["width_"+imgName],this.heights["height_"+imgName])
                //
                // this.canvas[can+"_"+imgName].setAttribute("width",this.widths["width_"+imgName]);
                // this.canvas[can+"_"+imgName].setAttribute("height",this.heights["height_"+imgName]);
                //
                // this.canvas[can+"_"+imgName].getContext("2d").drawImage(img, 0, 0,this.widths["width_"+imgName], this.heights["height_"+imgName]);
                //
                // img.style.display = "none"
            },
            drawPicCircle(can,imgName,areaArrTemp,color){
                let picX = areaArrTemp.x
                let picY = areaArrTemp.y
                let picR = areaArrTemp.r
                let picSx = areaArrTemp.sx
                let picSy = areaArrTemp.sy
                let picRa = areaArrTemp.ra
                let canvans = document.getElementById(can+"_"+imgName)

                if(color!= undefined){
                    // console.log("{\"x\":"+picX+",\"y\":"+picY+",\"r\":"+picR+",\"sx\":"+picSx+",\"sy\":"+picSy+",\"ra\":"+picRa+",\"type\":0}")
                    canvans.getContext("2d").save();


                    if(picRa!=0){
                        canvans.getContext("2d").translate(picX*this.sizeRa, picY*this.sizeRa)
                        canvans.getContext("2d").rotate(picRa * Math.PI / 180 )
                    }

                    canvans.getContext("2d").beginPath();

                    canvans.getContext("2d").fillStyle=color;
                    canvans.getContext("2d").globalAlpha =0.5
                    canvans.getContext("2d").scale(picSx, picSy);
                    canvans.getContext("2d").arc(picX*this.sizeRa,picY*this.sizeRa,picR*this.sizeRa,Math.PI*2,0,true);
                    canvans.getContext("2d").fill();
                    canvans.getContext("2d").closePath();
                    canvans.getContext("2d").restore();
                }


            },
            drawPicRect(can,imgName,areaArrTemp,color){
                let picX = areaArrTemp.x
                let picY = areaArrTemp.y
                let picW = areaArrTemp.w
                let picH = areaArrTemp.h
                let picRa = areaArrTemp.ra
                // console.log("{\"x\":"+picX+",\"y\":"+picY+",\"w\":"+picW+",\"h\":"+picH+",\"ra\":"+picRa+",\"type\":1}")
                // console.log(this.sizeRa)
                let canvans = document.getElementById(can+"_"+imgName)
                // console.log(canvans)
                if(color!= undefined){
                    canvans.getContext("2d").save();

                    if(picRa!=0){
                        canvans.getContext("2d").translate(picX*this.sizeRa, picY*this.sizeRa)
                        canvans.getContext("2d").rotate(picRa * Math.PI / 180 )
                    }

                    canvans.getContext("2d").fillStyle =color
                    canvans.getContext("2d").globalAlpha =0.5
                    canvans.getContext("2d").fillRect(picX*this.sizeRa, picY*this.sizeRa, picW*this.sizeRa, picH*this.sizeRa);
                    canvans.getContext("2d").restore();
                }

            },
            drawPic(){
                // console.log("画图")
                this.loadColor()
                for(let i=0;i<this.bwInfo.imgs.length;i++){
                    // if($scope.changeReViewDraw ==1){
                    //     initCanvas("can2",this.bwInfo.imgs[i],"2")
                    //
                    // }
                    // console.log($scope.bwAreaHash)
                    setTimeout(()=>{

                        this.initCanvas("can",this.bwInfo.imgs[i],"")

                        setTimeout(()=>{
                            let areaArrTemp = this.bwInfo.areas[this.bwInfo.imgs[i]]
                            // console.log($scope.bwAreaArr.length)

                            for(let j=0;j<areaArrTemp.length;j++){

                                if(areaArrTemp[j]!=undefined&&areaArrTemp[j]!=null&&i<areaArrTemp.length){
                                    if(areaArrTemp[j].type==0){//圆
                                        this.drawPicCircle("can",this.bwInfo.imgs[i],areaArrTemp[j],this.bwAreaColorArr[j])
                                        // if($scope.changeReViewDraw ==1){
                                        //     drawPicCircle("can2",this.bwInfo.imgs[i],areaArrTemp[j],$scope.bwAreaColorArr[j])
                                        //
                                        // }
                                    }else if(areaArrTemp[j].type==1){//方
                                        this.drawPicRect("can",this.bwInfo.imgs[i],areaArrTemp[j],this.bwAreaColorArr[j])
                                        // if($scope.changeReViewDraw ==1){
                                        //     drawPicRect("can2",this.bwInfo.imgs[i],areaArrTemp[j],$scope.bwAreaColorArr[j])
                                        //
                                        // }
                                    }
                                }

                            }
                        },100)

                    },200)


                }
            },
          preItem(){

            if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].required==1){
              if(this.crfdatas["__"+this.crf[this.currentitem].name]== undefined || this.crfdatas["__"+this.crf[this.currentitem].name].value == ""){
                this.$toast('请完成本题目');
                return
              }
            }
            // console.log("上一题")
            if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasend')>-1){
              console.log(1)
              for(let i=this.currentitem;i>=0;i--){
                if(this.crf[i].name.indexOf('vasstart')>-1){
                  break
                }
                this.currentitem = this.currentitem-1
              }
            }
            this.currentitem = this.currentitem-1
            if(this.currentitem>0){
              this.isStart =0
              this.isFinish =0
              // console.log("当前是第"+(this.currentitem+1)+"题")
            }else{
              this.isStart =1
              this.isFinish =0
              // console.log("当前是第"+(this.currentitem+1)+"题")
            }
            if(this.crf[this.currentitem].tagType =='header'){
              this.currentitem = this.currentitem-1

            }
            if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasend')>-1){
              for(let i=this.total-1;i>=0;i--){
                if(i == this.currentitem){
                  this.showvas.push(this.currentitem)
                  if(this.crf[i].name.indexOf('vasstart')>-1){
                    this.bwimg = this.currentitem
                    break
                  }
                  this.currentitem --;
                }
              }
            }else{
              this.bwimg = null
              this.showvas=[]
            }
            // console.log(this.showHeaders,"current",this.currentitem)

            this.showHeaders=[]
            for(let i=this.currentitem;i>0;i--) {
              if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasstart')>-1) {
                if (this.crf[i-1].tagType == "header") {
                  this.showHeaders.push(this.crf[i-1].no)
                  this.currentitem--;
                }
              }else if (this.crf[i].tagType == "header") {
                this.showHeaders.push(this.crf[i].no)
                this.currentitem--;
              }else{
                if(i > 0){
                  if (this.crf[i-1].tagType == "header") {
                    this.showHeaders.push(this.crf[i-1].no)
                    // this.currentitem--;
                  }
                }
                break
              }
            }
            if(this.crf[this.currentitem].phidden==1){
              this.currentitem --
              for(let i=this.currentitem;i>-1;i--){
                if(this.crf[i].phidden==1){
                  this.currentitem --
                }else{
                  break
                }
              }
            }
            // console.log(this.showHeaders,"current",this.currentitem)
            // console.log("current",this.currentitem)
            if(this.currentitem>0){
              if(this.currentitem ==1 && this.crf[0].tagType == "header"){
                this.isStart =1
                this.isFinish =0
              }else{
                this.isStart =0
                this.isFinish =0
              }

              // console.log("当前是第"+(this.currentitem+1)+"题")
            }else{
              this.isStart =1
              this.isFinish =0
              // console.log("当前是第"+(this.currentitem+1)+"题")
            }
            // console.log("-----当前是第"+(this.currentitem+1)+"题")
          },
          nextItem(){
            // console.log(this.crfdatas)
            this.showHeaders=[]

            for(let i=this.currentitem;i<this.crf.length-1;i++) {
              if (this.crf[i+1].tagType == "header") {
                this.showHeaders.push(this.crf[i+1].no)
                this.currentitem++;
              }else{
                break
              }
            }

            if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].required==1){
              if(this.crfdatas["__"+this.crf[this.currentitem].name]== undefined || this.crfdatas["__"+this.crf[this.currentitem].name].value == ""){
                this.$toast('请完成本题目');
                return
              }
            }
            if((this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasstart')>-1)||(this.crf[this.currentitem].tagType =='header'&&this.crf[this.currentitem+1].name.indexOf('vasstart')>-1)){
              console.log(1)
              for(let i=this.currentitem;i<this.total;i++){
                if(this.crf[this.currentitem].tagType !='header'&&this.crf[i].name.indexOf('vasend')>-1){
                  break
                }
                this.currentitem = this.currentitem+1
              }
            }
            // console.log("current",this.currentitem)
            this.currentitem = this.currentitem+1

            console.log(this.currentitem)
            if(this.currentitem<this.total-1){
              this.isStart =0
              this.isFinish =0
              // console.log("当前是第"+(this.currentitem+1)+"题")
            }else{
              this.isStart =0
              this.isFinish =1
              // console.log("当前是第"+(this.currentitem+1)+"题")
            }
            if((this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].name.indexOf('vasstart')>-1)||(this.crf[this.currentitem].tagType =='header'&&this.crf[this.currentitem+1].name.indexOf('vasstart')>-1)){
              this.bwimg = this.currentitem
              for(let i=0;i<this.total;i++){
                if(i == this.currentitem){
                  this.showvas.push(this.currentitem)
                  if(this.crf[this.currentitem].tagType !='header'&&this.crf[i].name.indexOf('vasend')>-1){
                    break
                  }
                  this.currentitem ++;
                }
              }
            }else{
              this.bwimg = null
              this.showvas=[]
            }
            // console.log("-----当前是第"+(this.currentitem+1)+"题")
            if(this.crf[this.currentitem].name=="zcwj00095"){
              this.showQsbw = true
            }else{
              this.showQsbw = false
            }
            // console.log(this.showHeaders,"current",this.currentitem)
            let checkHidden = this.currentitem
            for(let i=this.currentitem;i<this.total-1;i++){
              if(this.crf[i+1].phidden==1){
                checkHidden ++;
              }else{
                break
              }
            }
            if(checkHidden>=this.total-1){
              this.isStart =0
              this.isFinish =1
              // console.log("当前是第"+(this.currentitem+1)+"题")
              if(this.crf[this.currentitem].phidden==1){
                this.currentitem ++
              }
            }else{
              if(this.crf[this.currentitem].phidden==1){
                // this.currentitem ++
                for(let i=this.currentitem;i<this.total-1;i++){
                  if(this.crf[i].phidden==1){
                    this.currentitem ++
                  }else{
                    break
                  }
                }
              }
            }

          },
            onSubmit(){
              if(this.crf[this.currentitem].tagType !='header'&&this.crf[this.currentitem].required==1){
                if(this.crfdatas["__"+this.crf[this.currentitem].name]== undefined || this.crfdatas["__"+this.crf[this.currentitem].name].value == ""){
                  this.$toast('请完成本题目');
                  return
                }
              }
                console.log(this.crfdatas)
                // console.log(values)
                // for(let key in values){
                //     if(typeof([])==typeof(values[key])){
                //         this.crfdatas[key]=values[key].join(",");
                //     }else{
                //         this.crfdatas[key]=values[key];
                //     }
                // }
                // console.info(this.crfdatas);
                // $scope.subParams = {questNum:0,crfdatas:{},compNum:0,o:null,crfid:null,itemdataorderid:$scope.itemdataorderid,sendid:$scope.sendid,openid:null};
                // let crfid = "0b6ed707cd8c4e38ad96b8a9177abb97"
                // saveRegisterCrf({crfid:this.$route.params.crfid,pid:this.$store.state.user.pid},this.crfdatas).then((response) => {
                this.sub = true
                this.subText = "正在提交"
                let data={};
                saveFollowCrf({patientid:this.user.id,crfid:this.crfid,itemdataorderid:this.itemdataorderid,sendid:this.sendid},{crfdatas:JSON.stringify(this.crfdatas)}).then((response) => {
                    if(response.code==200){
                        this.$toast('保存成功');
                        if(this.is_type==1||this.is_type=='1'){
                            this.$router.push({path: '/diary/',query:{}});

                        }else if(this.is_type==2||this.is_type=='2'){
                            this.$router.push({path: '/tjzb/',query:{}});

                        }else if(this.is_type==3||this.is_type=='3'){
                            this.$router.push({path: '/weixin/send-crf-list-empty/',query:{}});

                        }else{
                            this.$router.push({path: '/crfs/',query:{}});

                        }
                        console.log(response)
                    }else{
                        //        this.$router.push({path: '/err'});
                        this.$toast('保存出错请稍候再试');
                    }

                });
                this.crfdatas = Object.assign(this.crfdatas, data)
                console.info(this.crfdatas);
            },
            showFWXY(){
                this.showFwxy = true;
            },
            showYSZC(){
                this.showYszc = true;
            },
            changeAgree(ischecked){
                if(ischecked == true){
                    this.agreeStatus = false
                }else{
                    this.agreeStatus = true

                }
            },
          setData(groupname,index,itemname,value){
            //console.log('父组件'+ groupname +'-' + index + '-' + itemname + ':' )
            // console.log("赋值",itemname,value)
            if(groupname != null && groupname != ''){
              //console.log(this.data.groups[groupname].length)
              var g = this.crfdatas.groups[groupname][index]
              g[itemname] = value


              this.$set(this.crfdatas.groups[groupname],index,g)
            }else{
              // this.$set(this.crfdatas,itemname+'_0',value.value)
              // console.log(typeof value)
              // if(typeof value.value == "number"){
              //   value.value = this.keepThreeDecimal(value.value)
              // }
              // if(typeof value.text == "number"){
              //   value.text = this.keepThreeDecimal(value.text)
              // }
              this.crfdatas['__'+itemname] = value
            }
          },

        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            let query = this.$route.query;
            let patientid=query.patientid;
            console.log(patientid)
            getUser({patientid:patientid}).then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();

                this.initData();

              }
            });
          }else{
            //生产环境
            let query = this.$route.query;
            let codeTemp = getToken();
            if(query.code || this.getQueryString('code') || query.patientid ||codeTemp){
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              // let query = this.$route.query;
              let patientid=query.patientid;
              console.log(patientid)
              getUser({patientid:patientid}).then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }


        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
